import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Sectiontitle from '../section-title'
import strory1 from '../../images/events/img1.jpg'
import strory2 from '../../images/events/img2.jpg'
import strory3 from '../../images/events/img3.jpg'

import './style.css'

const Location = () => {
    return(
        <div id="event" className="event-section section-padding">
            <Sectiontitle section={'When & Where'}/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory1} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Wedding Ceremony</h3>
                                                    <span>Saturday, 15 August 2020, 4.00 PM-6.00 PM</span>
                                                    <span>24590 Main St, Foresthill, CA 95631</span>
                                                    <p>The ceremony site is around 5 minutes driving from the Lodge. You'll find it a beautiful view looking down the valley. There is amper parking around the site and there will be attendants helping guests with parking and direction. </p>
                                                    <div className="btn"><a href='https://drive.google.com/file/d/1jY9OQoDVBqma83J7Qa5RelQseWZQd1wK/view?usp=sharing'>Timeline</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>Wedding Party</h3>
                                                    <span>Saturday, 15 August 2020, 8.00 PM-10.00 PM</span>
                                                    <span>24590 Main St, Foresthill, CA 95631</span>
                                                    <p>Join us at the Party! After the cake cutting, we will stay for a formal dance, then it will be open dancing for everybody. Our chosen DJ will make sure you dance to your heart's content!</p>
                                                    <div className="btn"><AnchorLink href='#location'>Location</AnchorLink></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory2} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory3} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Wedding Dinner</h3>
                                                    <span>Saturday, 15 August 2020, 7.00 PM-8.00 PM</span>
                                                    <span>24590 Main St, Foresthill, CA 95631</span>
                                                    <p>After the ceremony, we will go back to the Lodge to enjoy dinner with us in the beautiful venue's courtyard. It is a traditional family-style dining, and we surely hope you enjoy our food offerings! If you have special dining requests such as vegan or non-allergy options, please let us know beforehand. </p>
                                                    {/* <div className="btn"><AnchorLink href='/'>Menu</AnchorLink></div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;