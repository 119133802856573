import React, { Component } from 'react';
import DateCountDown from 'react-date-countdown-timer';
import './style.css'
 
class Saveday extends Component {

    render() {
        return(
            <div className="saveday">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="countdownwrap">
                                <DateCountDown
                                    className='react-countdown'
                                    dateTo="August 15, 2020"
                                    mostSignificantFigure='day'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}
 
export default Saveday;