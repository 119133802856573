import React from 'react'
import Sectiontitle from '../section-title'
import husband from '../../images/couple/husband.jpg'
import wife from '../../images/couple/wife.jpg'
import './style.css'

const Couple = () => {
    return(
        <div id="couple" className="about-wrap">
            <div className="couple-area section-padding">
                <Sectiontitle section={'Happy Couple'}/>
                <div className="container">
                    <div className="couple-wrap">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 couple-single">
                                <div className="couple-wrap couple-wrap-2">
                                    <div className="couple-img">
                                        <img src={wife} alt="thumb"/>
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Luna Tran</h3>
                                            <p>Hi I am Giang Tran (Luna). I studied to be an RDA. In my free time, I enjoy spending time with Daniel, working on my art gigs and watching TV shows. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 couple-single">
                                <div className="couple-wrap couple-wrap-3">
                                    <div className="couple-img couple-img-2">
                                        <img src={husband} alt="thumb"/>
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Daniel Mai</h3>
                                            <p>Hi I am Nghia Mai (Daniel). I am a professional software engineer. When I have free time, I like going out with Luna, exercising and playing some video games!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             
        </div>
        
    )
}

export default Couple;