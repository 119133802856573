import React from  'react';
import gta2 from '../../images/gta/img1.jpg';
import Sectiontitle from '../section-title';
import Iframe from 'react-iframe';
import './style.css';

const Getting = () => {
    return(
        <div id='location' className="gta-area section-padding">
            <Sectiontitle section={'Getting There'}/>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div className="row">
                            <div className="heading col-md-12 col-sm-6">
                                <h4>Location</h4>
                                <div className="gta-content">
                                    <p>The venue is located North-East of Sacramento, about 2 hours driving from the Bay Area. To get direction to the venue, please click on the Direction link down below.</p>
                                    <div className="btn-wrapper"><div className="btn"><a href="https://goo.gl/maps/iXagvYi1ayrwJPrG7">Direction</a></div></div>
                                   
                                </div>
                                <div className="gta-map">
                                    <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3099.78631271303!2d-120.81842774820227!3d39.02018777945147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809ba61ada923b2f%3A0xf0024cd984de08e!2sForest%20House%20Lodge!5e0!3m2!1sen!2sus!4v1593922732881!5m2!1sen!2sus"
                                        width="730px"
                                        height="450px"
                                    />
                                </div>
                            </div>
                            <div className="heading heading-2 col-md-12 col-sm-6">
                                <h4>Accommodations</h4>
                                <div className="gta-content">
                                    <p>Our wedding party will most likely happen until 2AM, so we have prepared rooms for you to spend a night at the venue. All rooms are conveniently located upstairs where the party is, so do not worry that you have to drink and drive! When checking in, just mention you are here for Luna & Daniel wedding, and you will be presented with a room key reserved for you.</p>
                                </div>
                                <div className="gta-img">
                                <img src={gta2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Getting;