import React from 'react'
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import story1 from '../../images/story/img1.png'
import story2 from '../../images/story/img2.jpg'
import story3 from '../../images/story/img3.jpg'
import story4 from '../../images/story/img4.jpg'
import './style.css'


const Story = () =>{
    return(
        <div id="story" className="story-area section-padding">
            <Sectiontitle section={'Our Love Story'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={story1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>First time we met</h3>
                                <span className="date">November 10 2013</span>
                                <p>We met first time in a Seventh-Day Adventist Church back in 2013. We joined together for a Bible study, even though we were not particularly religious at the time. Luna wasn't pay attention to me at first, but I did, and I was the one who initialized the conversation. </p>
                                {/* <div className="story-button">
                                    <Link className="theme-btn" to='/'>Read More</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Our First Date</h3>
                                <span className="date">May 25, 2014</span>
                                <p>Our first date was very simple. I remember it was summer time, and I asked if she would like some ice cream. We went downtown and bought some at the store. When we were about to go home, my phone was suddently losing signal, and I had to ask her for GPS direction (she's still thinking I tricked her haha.) We're so close to each other that I could smell her hair. On the way back, I gathered my strength and held her hand while driving. She allowed me to keep it there, and the rest was history :)</p>
                                {/* <div className="story-button">
                                   <Link className="theme-btn" to='/'>Read More</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={story2} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={story3} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Our Engagement</h3>
                                <span className="date">October 15, 2017</span>
                                <p>We met together with our family and relatives to announce our engagement in 2017. It was a great time and everybody knew that we are serious about our relationship.</p>
                                {/* <div className="story-button">
                                   <Link className="theme-btn" to='/'>Read More</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Our Marriage Proposal</h3>
                                <span className="date">Feb 16, 2020</span>
                                <p>It was Valentine and I wanted to do something special for her. I had prepared the ring and took her to a lake in San Francisco where we would go pedal boating. The scene was so beautiful and romantic. When we were coming to a hidden place on  the lake, I presented the ring and asked if she would like to marry me. She said yes!</p>
                                {/* <div className="story-button">
                                   <Link className="theme-btn" to='/'>Read More</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={story4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;