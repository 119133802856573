import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'


const Welcome = () =>{
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Welcome to our big day</h2>
                            <p>Thank you for being part of our experience in this special moment. We're touched by your consideration and thrilled that you are able to make it to our wedding, amidst the current situation. We appreciate all your presence, from the bottom of our hearts. Thank you! </p>
                            <div className="btn"><AnchorLink href='#rsvp'>RSVP</AnchorLink></div>
                            <div className="btn"><AnchorLink href='#location'>Direction</AnchorLink></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Welcome;