import React from  'react';
import Sectiontitle from '../section-title'
import mateOfHonor from '../../images/groomsmen-bridesmaid/thy.jpg'
import bestMan from '../../images/groomsmen-bridesmaid/trieu.jpg'
import bridesmaid from '../../images/groomsmen-bridesmaid/truc.jpg'
import groomsman from '../../images/groomsmen-bridesmaid/connor.jpg'

import './style.css'

const People = () => {
    return(
        <section id='people'>
            <div className="groomsmen-bridesmaid-area section-padding">
                <Sectiontitle section={'Groomsmen & Bridesmaid'}/>
                <div className="container">
                    <div className="groomsmen-bridesmaid-area-menu">
                        <div className="Groomsman-wrap">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap">
                                        <div className="groomsmen-bridesmaid-img">
                                            <img src={mateOfHonor } alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content">
                                            <h4>Thy Tran</h4>
                                            <span>Luna's Sister</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap groomsmen-bridesmaid-wrap-2">
                                        <div className="groomsmen-bridesmaid-img">
                                        <img src={bestMan} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content">
                                            <h4>Andrew Dinh</h4>
                                            <span>Best Man</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap">
                                        <div className="groomsmen-bridesmaid-img">
                                        <img src={bridesmaid } alt="bride"/>

                                        </div>
                                        <div className="groomsmen-bridesmaid-content">
                                            <h4>Truc Wilson</h4>
                                            <span>Daniel's Sister</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 grid">
                                    <div className="groomsmen-bridesmaid-wrap groomsmen-bridesmaid-wrap-2">
                                        <div className="groomsmen-bridesmaid-img">
                                        <img src={groomsman} alt="bride"/>
                                        </div>
                                        <div className="groomsmen-bridesmaid-content">
                                            <h4>Connor Wilson</h4>
                                            <span>Groomsman</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default People;